import axios from 'axios'
import { Config } from '~/config/config'
import { Logger } from '~/util'
import { HttpClient, HttpServer, ObjToQueryString } from './htttp'

export enum CHANNEl {
  ONLINE = 'ONLINE',
  SHOP = 'SHOP',
}
export class ProductService {
  static PimDelivery(arg0: { item_code: number; pin_code: number }) {
    throw new Error('Method not implemented.')
  }
  public static async productCompare(data: {
    products: {
      item_code: string
      product_id: number
    }[]
  }) {
    return HttpClient.post('/base/productCompare', data)
  }

  public static productDetails(data: any) {
    if (!!data.slug) {
      return HttpClient.get(`/base/variant/${data.slug}`)
    }
    return HttpClient.get(
      `/base/product/productDetail?product_id=${data.product_id}&item_code=${data.item_code}`
    )
  }

  public static async boughtTogether() {
    return HttpClient.get('/base/boughttogether')
  }

  public static async pinCodeBasedProducts(pinCode: any) {
    return HttpClient.get(`/base/pincodeBasedProducts/${pinCode}`)
  }

  public static async recentProductDetails(data: {
    search_list: {
      product_id: string
      item_code: string
    }[]
  }) {
    return HttpClient.post('/base/recentProduct/productDetails', data)
  }

  public static async relatedProducts(slug: string) {
    return HttpClient.get(`/pim/product/${slug}/relatedproducts`, {
      headers: {
        channel: CHANNEl.ONLINE,
        locale: 'en-IN',
      },
    })
  }

  public static async recentProducts() {
    return HttpClient.get('/base/recentProduct')
  }

  public static async recentProduct(data: {
    search_list: {
      product_id: string
      item_code: string
    }[]
  }) {
    return HttpClient.post('/base/recentProduct', data)
  }

  // public static async deliveryCheck(data: {
  //   item_code: number
  //   pin_code: number
  // }) {
  //   return HttpClient.get(`/base/deliveryCheck${ObjToQueryString(data)}`)
  //     .then((res) => {
  //       Logger.log('NRES', res.data.data)
  //       let order = [
  //         '2 hours',
  //         'Same Day',
  //         'Next Day',
  //         'Regular',
  //         'Pickup@store',
  //       ]
  //       let values = [...res.data.data].sort((x: any, y: any) => {
  //         return order.indexOf(x.mode) > order.indexOf(y.mode) ? 1 : -1
  //       })
  //       return { data: { data: values } }
  //     })
  //     .catch((err) => Promise.reject(err))
  // }

  // public static async pickupStoreDetail(
  //   id: any,
  //   data: {
  //     item_code: number
  //     pin_code: number
  //   }
  // ) {
  //   return HttpClient.get(`/base/deliveryCheck/${id}${ObjToQueryString(data)}`)
  // }

  public static async emiData(data: {
    payment_gateway: string
    product_code: string
    product_amount: number
    itemcode: any
  }) {
    return HttpClient.get(
      `/crm/emicalc/emi?payment_gateway=${data.payment_gateway}&product_code=${data.product_code}&product_amount=${data.product_amount}&itemcode=${data.itemcode}`
    )
  }

  public static async getRecentlyViewedProducts() {
    return HttpClient.get('/crm/recentproducts', {
      headers: {
        channel: 'ONLINE',
      },
    })
  }

  public static async addRecentlyViewProducts(data: {
    search_list: {
      slug: string
      item_code: string
    }[]
  }) {
    return HttpClient.post(
      '/crm/recentproducts',
      {
        search_list: data.search_list,
        view_product_time: new Date().toISOString(),
      },
      {
        headers: {
          channel: 'ONLINE',
        },
      }
    )
  }

  public static async getRecentViewedAnonymous(
    data: {
      slug: string
      item_code: string
    }[]
  ) {
    return HttpClient.post(
      '/crm/recentproducts/products',
      {
        search_list: data,
      },
      {
        headers: {
          channel: 'ONLINE',
        },
      }
    )
  }

  public static async getAdld(itemCode: string) {
    return HttpServer.get(`/pim/product/${itemCode}/addons`, {
      headers: {
        channel: CHANNEl.ONLINE,
        locale: 'en-IN',
      },
    })
  }
  public static async getProductPolicies(itemCode: string) {
    return HttpServer.get(`/pim/product/${itemCode}/policy`, {
      headers: {
        channel: CHANNEl.ONLINE,
        locale: 'en-IN',
      },
    })
  }

  public static async getProductDetail(slug: string) {
    return HttpServer.get(`/base/variant/${slug}`)
  }

  public static async getProductDetailPIM(
    slug: any,
    channel: CHANNEl,
    locale: string,
    includespecification?: number
  ) {
    return HttpServer.get(
      `/pim/product/${slug}?includespecification=${includespecification}`,
      {
        headers: {
          channel: channel,
          locale: locale,
        },
      }
    )
  }
  public static async ProductCompare(slug: any) {
    return HttpServer.get(`/pim/product/${slug}/compare`, {
      headers: {
        channel: 'ONLINE',
        locale: 'en-IN',
      },
    })
  }
  public static async getProductBoughtTogetherPIM(slug: string) {
    return HttpClient.get(`/pim/product/${slug}/bought-togather`, {
      headers: {
        channel: CHANNEl.ONLINE,
        locale: 'en-IN',
      },
    })
  }
  public static async getProductOffersPIM(slug: any) {
    return await HttpClient.get(`/offers/ruleEngine/offers/${slug}`, {
      headers: {
        'x-api-key': Config.OFFERS_KEY,
        channel: CHANNEl.ONLINE,
      },
    })
  }
  public static async getProductEffectivePrice(slug: any, shopcode: string) {
    return await HttpClient.post(
      `/offers/ruleEngine/getOffers/${slug}?shopcode=${shopcode}`,
      {},
      {
        headers: {
          'x-api-key': Config.OFFERS_KEY,
          channel: CHANNEl.ONLINE,
        },
      }
    )
  }
  public static async getProductNbfcData(slug: any) {
    return await HttpClient.get(`/offers/nbfc/showFinance/${slug}`, {
      headers: {
        'x-api-key': Config.OFFERS_KEY,
        channel: CHANNEl.ONLINE,
      },
    })
  }

  public static async getNotifyMe(data: {
    itemCode: string
    type: string
    isLogin: boolean
    name: string
    smsNotify: boolean
    emailNotify: boolean
    whatsAppNotify: boolean
    mobileNumber: string
    emailId: string
    slug: any
    model: string
    category: string
    brand: string
    platFormMode: string
  }) {
    return await HttpClient.post('/service-center/notifyMe', data, {
      headers: {
        Authorization: Config.NOTIFY_ME_SECURITY_CODE,
      },
    })
  }
}

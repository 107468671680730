import Link from 'next/link'
import { FC, useState, useLayoutEffect, useRef } from 'react'
import { CheveronLeft } from '../icons/cheveron-left'
import { CheveronRight } from '../icons/cheveron-right'
import Img from '../img/img'
import { InnerHtml } from '../inner-html/inner-html'

import {
  MultiSelectTag,
  MultiSelectTagType,
} from '../multi-select-tag/multi-select-tag'
import style from './horizontal-scroll-wrapper.module.scss'

export enum HorizontalScrollType {
  NORMAL,
  SOLO,
  OVERFLOW,
}

interface HorizontalScrollWrapperProps {
  /**
   * array of children
   */
  children: any
  /**
   * title for the scrollbar  j
   */
  title?: string
  image?: string
  /**
   * this will show filter
   */
  withFilter?: boolean
  showAllLink?: object
  sliderButton?: boolean
  products?: boolean
  colWidth?: string
  type: HorizontalScrollType
  scrollLength?: number
  smoothBorder?: string
  setPage?: any
  page?: any
  customScroll?: (cb: () => void) => void
  className?: any
  headerhtml?: any
  proplist?: any
  buttoncustom?: boolean
  bgcolor?: string
  tabSelected?: any
  headbgcolor?: any
  headervisible?: any
  backimage?: any
  arrowvisible?: any
  count?: any
}

export const HorizontalScrollWrapper: FC<HorizontalScrollWrapperProps> = ({
  children,
  title,
  withFilter,
  showAllLink,
  sliderButton,
  products,
  colWidth,
  type,
  scrollLength,
  smoothBorder,
  customScroll,
  className,
  headerhtml,
  proplist,
  image,
  buttoncustom,
  bgcolor,
  tabSelected,
  headbgcolor,
  headervisible,
  backimage,
  arrowvisible,
  count,
}) => {
  const typeClass = {
    [HorizontalScrollType.SOLO]: 'scroller--solo',
    [HorizontalScrollType.NORMAL]: '',
    [HorizontalScrollType.OVERFLOW]: 'scroller--overflow',
  }
  const wrapperEle: any = useRef(null)

  const scrollL = () => scrollLength || wrapperEle.current.clientWidth

  const scrollRight = () => {
    const distance = wrapperEle.current.scrollLeft + scrollL()
    if (!!customScroll) {
      customScroll(() => {
        wrapperEle.current.scrollTo({ left: distance, behavior: 'smooth' })
      })
    } else {
      wrapperEle.current.scrollTo({ left: distance, behavior: 'smooth' })
    }
  }

  const scrollLeft = () => {
    const distance = wrapperEle.current.scrollLeft - scrollL()
    wrapperEle.current.scrollTo({ left: distance, behavior: 'smooth' })
  }

  const horizontalScrollElement = useRef<HTMLDivElement | null | any>()

  const [eleWidth, setEleWidth] = useState(colWidth)

  useLayoutEffect(() => {
    if (!colWidth && count == undefined) {
      let width = 0
      if (window.innerWidth < 320) {
        width = horizontalScrollElement.current.scrollWidth / 1 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth > 320 && window.innerWidth < 484) {
        width = horizontalScrollElement.current.scrollWidth / 2 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth > 484 && window.innerWidth < 991) {
        width = horizontalScrollElement.current.scrollWidth / 3 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth >= 991 && window.innerWidth < 1200) {
        width = horizontalScrollElement.current.scrollWidth / 4 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth >= 1200 && window.innerWidth < 1600) {
        width = horizontalScrollElement.current.scrollWidth / 5 - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth >= 1600) {
        width = horizontalScrollElement.current.scrollWidth / 6 - 16
        setEleWidth(`${width}px`)
      }
    } else {
      let width = 0
      if (window.innerWidth <= 768) {
        width = horizontalScrollElement.current.scrollWidth / count - 16
        setEleWidth(`${width}px`)
      } else if (window.innerWidth >= 769) {
        width = horizontalScrollElement.current.scrollWidth / count - 16
        setEleWidth(`${width}px`)
      }
    }
    return () => {}
  }, [tabSelected])

  return (
    <div
      ref={horizontalScrollElement}
      style={{
        borderRadius: `${smoothBorder}`,
        backgroundColor: `${bgcolor?.length > 1 && bgcolor}`,
        backgroundImage: `url(${backimage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
      className={` ${className && style.scroller_pad} ${style.scroller} ${
        bgcolor?.length > 1 && style.imagepad
      } ${products ? style.products : ''} ${
        arrowvisible?.sliderArrow == 'slidearrow' && 'relative'
      }`}
    >
      {type == HorizontalScrollType.NORMAL ? (
        <>
          {buttoncustom != true && (
            // scrolled header
            <div
              className={`${className ? className : style.scroller__head} ${
                headervisible == false
                  ? arrowvisible?.sliderArrow != 'slidearrow'
                    ? style.headvisibleflex
                    : style.headvisible
                  : ''
              } `}
              style={{
                backgroundColor: `${headbgcolor?.length > 1 && headbgcolor}`,
              }}
            >
              {/* show title if title exists */}

              <a rel="canonical" className="flex align-middle items-center">
                {image ? (
                  <img
                    height={40}
                    width={40}
                    src={image}
                    alt="image"
                    //loader={() => image}
                    //blurDataURL={image}
                    placeholder="blur"
                  />
                ) : (
                  ''
                )}
                <span className="font-semibold text-black text-lg pl-2 inline-block">
                  <p
                    style={{
                      color: proplist?.style?.fontColor,
                      fontSize: proplist?.style?.fontSize,
                      fontWeight: proplist?.style?.fontStyle?.isBold && 'bold',
                      fontStyle:
                        proplist?.style?.fontStyle?.isItalic && 'italic',
                      textDecoration:
                        proplist?.style?.fontStyle?.isUnderLine && 'underline',
                    }}
                  >
                    {title ? title : ''}
                  </p>
                </span>
              </a>
              {/* {!!headerhtml ? headerhtml : ''} */}
              {/* provide space between items */}
              <div className={style.spacer} />

              {/* show tags if withFilter if option exits */}
              {withFilter ? (
                <MultiSelectTag
                  name="price"
                  items={['one', 'two', 'three']}
                  onChange={(_va) => {}}
                  type={MultiSelectTagType.Multiple}
                />
              ) : (
                ''
              )}
              {showAllLink ? (
                <Link href={showAllLink} passHref className={style.show_all}>
                  See all
                </Link>
              ) : (
                ''
              )}
              <button
                className={`${
                  className ? style.overflow_btn_left : style.btn_left
                } ${
                  arrowvisible?.sliderArrow == 'slidearrow' && style.left_arrow
                }`}
                onClick={scrollLeft}
                aria-label="left"
                style={{
                  borderColor:
                    arrowvisible?.sliderArrow != 'slidearrow' &&
                    buttoncustom != undefined &&
                    proplist?.style?.fontColor,
                }}
              >
                <CheveronLeft
                  iconcolor={`${
                    arrowvisible == undefined
                      ? 'rgba(0, 0, 0, 0.8)'
                      : arrowvisible?.sliderArrow != 'slidearrow' &&
                        proplist?.style?.fontColor
                  }`}
                  slidarrow={
                    arrowvisible?.sliderArrow == 'slidearrow' &&
                    style.right_arrow
                  }
                />
              </button>
              <button
                className={`${
                  className ? style.overflow_btn_right : style.btn_right
                } ${
                  arrowvisible?.sliderArrow == 'slidearrow' && style.right_arrow
                }`}
                onClick={scrollRight}
                aria-label="right"
                style={{
                  borderColor:
                    arrowvisible?.sliderArrow != 'slidearrow' &&
                    buttoncustom != undefined &&
                    proplist?.style?.fontColor,
                }}
              >
                <CheveronRight
                  iconcolor={`${
                    arrowvisible == undefined
                      ? 'rgba(0, 0, 0, 0.9)'
                      : arrowvisible?.sliderArrow != 'slidearrow' &&
                        proplist?.style?.fontColor
                  }`}
                  slidarrow={
                    arrowvisible?.sliderArrow == 'slidearrow' &&
                    style.right_arrow
                  }
                />
              </button>
            </div>
          )}
        </>
      ) : (
        ''
      )}

      {type == HorizontalScrollType.SOLO ? (
        <button
          className={`${style.solo_btn} ${style['solo_btn--left']}`}
          onClick={scrollLeft}
        >
          <CheveronLeft />
        </button>
      ) : (
        ''
      )}
      {type == HorizontalScrollType.SOLO ||
      type == HorizontalScrollType.OVERFLOW ? (
        <button
          className={`${style.solo_btn} ${style['solo_btn--right']}`}
          onClick={scrollRight}
        >
          <CheveronRight />
        </button>
      ) : (
        ''
      )}

      <div
        className={`${style.scroller__wrapper} ${className} ${
          bgcolor?.length > 1 && style.imagegroup_pad
        }`}
        style={{
          gridTemplateColumns: `repeat(${children?.length}, ${
            eleWidth || '160px'
          })`,
          paddingBottom: `${bgcolor?.length > 1 && '14px'}`,
        }}
        ref={wrapperEle}
      >
        {children}
      </div>
    </div>
  )
}

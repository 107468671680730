import Link from 'next/link'
import { FC, useEffect, useRef, useState } from 'react'
import Img from '~/components/ui/img/img'
import MobileAppBar from '~/components/ui/mobile/mobile-app-bar/mobile-app-bar'
import { IsMobile } from '~/util'
import { useMounted } from '~/util/hooks'
const HeaderImg: FC<any> = (props) => {
  const [show, setShow] = useState(true)
  const parentRef = useRef(null)
  const [selectedIconData, setSelectedIconData] = useState(0)
  useMounted(
    parentRef,
    () => {
      if (IsMobile() || window.innerWidth < 550) {
        setShow(true)
      } else {
        setShow(false)
      }
    },
    []
  )
  const getIconData = (i) => {
    setSelectedIconData(i)
  }
  return show ? (
    <div
      ref={parentRef}
      className={`pt-4 pb-4 overflow-x-scroll overscroll-y-none whitespace-nowrap text-center ${props.style.overflow_menu}`}
    >
      <button
        className="inline-block align-top m-1"
        onClick={props.drawerToggleClickHandler}
        aria-label="Menu Navigation"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6 text-white mb-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>

      {/* {props.data?.map((item: any, i: number) => (
        <div
          key={i}
          className="inline-block w-20 align-top m-1"
          onClick={() => {
            props.drawerToggleClickHandler()
            getIconData(i)
          }}
        >
          <Link href={''}>
            <a>
              <Img
                withoutBase
                className={`${props.style}`}
                src={`${item.image?.url}`}
                alt="Product Card"
                width={28}
                height={28}
                objectFit="contain"
                loader={() => `${item.image?.url}`}
                blurDataURL={`${item.image?.url}`}
                placeholder="blur"
              />
            </a>
          </Link>
          <span className="text-xs block whitespace-normal text-center mt-3 text-gray-500 leading-none">
            {item.name}
          </span>
        </div>
      ))} */}
      {props.drawerOpen && (
        <MobileAppBar
          data={props.data}
          setSelectedIconData={setSelectedIconData}
          selectedIconData={selectedIconData}
          drawerOpen={props.drawerOpen}
          setDrawerOpen={props.setDrawerOpen}
          backdrop={props.backdrop}
        ></MobileAppBar>
      )}
    </div>
  ) : (
    <></>
  )
}
export default HeaderImg
